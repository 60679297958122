<template>
	<div class="titlebar">
		<div class="titledesc">
			<img src="../assets/警徽3.png" style="height:80%" >
			<h1 style="font-size:1.3rem;">公共场所电子显示屏  内容安全智能分析管理平台</h1>
		</div>
		<div class="userinfo">
			<img src="../assets/提醒.png" style="height:60%" >
			<p style="font-weight: bold;">{{ username }}</p>
			<img src="../assets/图层1.png" style="height:80%" >
		</div>
	</div>
	<div class="containter" ref='maincontainer'>
		<TabBar @tabchanged="tabChanged" v-if="false"/>
		<div class="tab_c">
			<AiDefender v-if="showIndex==2" />
			<LiveCtrl v-if="showIndex==1" />
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import TabBar from './TabBar.vue'
import AiDefender from "./tabitems/AiDefender.vue"
import LiveCtrl from "./tabitems/streamitem/LiveCtrl.vue"
export default {
	components:{
		TabBar,
		AiDefender,
		LiveCtrl
	},
	data(){
		return{
			showSites:[],
			isshowOutlaw: false,
			isshowUserConfig: false,
			username: '',
			showIndex: 2,
			showtabbar: false,
		}
	},
	mounted(){
		const self = this
		axios.get('/aishow/getuserlevel', {
			withCredentials: true
		}).then(res=>{
			self.username = res.data.username
		})
	},
	methods: {
		siteChanged(sites){
			this.isshowOutlaw = false
			this.showSites = sites
		},
		siteOutlawChanged(sites){
			this.isshowOutlaw = true
			this.showSites = sites
		},
		tabChanged(index){
			if(index != 1 && index != 2){
				return
			}
			this.showIndex = index
		}
	},
}
</script>

<style scoped>
.titlebar{
	width:100%;
	height:5%;
	display: flex;
	align-items: center;
	padding-left:20px;
	padding-right:20px;
	border-bottom: 1px solid #cecece;
	gap: 10px;
	justify-content: space-between;
}

.titledesc{
	height: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.userinfo{
	height: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.containter{
	width:100%;
	height:95%;
	display: flex;
}

.tab_c{
	width:100%;
	background-color: #f2f0f0;
	padding: 1% 1% 0.5% 1%;
}
</style>