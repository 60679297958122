<template>
    <div class="msg_c">
        <div class="msgtitle">
            <h1 style="font-weight:bold">待处理强关</h1>
            <n-button
                strong
                round
                text=true
                size="small">
                查看更多>>
            </n-button>
        </div>
        <div class="msglist">
            <ul>
                <li>
                    <div class="msgitem">
                        <img src="../../../assets/电脑.png" style="height:70%" >
                        <p>namdffffffffffffffffffe</p>
                        <p>addffffffffffffffffffffffffffffffffffffffffdddddddffffffffr</p>
                        <p title="时间：2024-6-24 11:12:10">时间：2024-6-24 11:12:10</p>
                        <n-button
                            strong
                            round
                            type="primary"
                            color="#ff750f"
                            size="small">
                            待处理
                        </n-button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { NButton } from 'naive-ui';
export default ({
    components:{
        NButton
    },
	data() {
		return {
			serverUrl: '',
		}
	},
	mounted() {

	},
})


</script>

<style scoped>
ul{
    list-style-type: none;
}

.msg_c{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
    background-color: #fff;
}

.msgtitle{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1% 3% 1% 3%;
}

.msglist{
    width: 100%;
    height: 85%;
}

.msgitem{
    padding: 0% 3%;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:2%;
    background-color: #eff4ff;
}

p{
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
</style>