<template>
<div class="show_area">
    <div class="imgage_containter">
        <div class="image" v-for="imageinfo in imagelist" :key="imageinfo.id">
            <ul style="padding-left:20px;">
                <li>
                    <p class="desc">{{ imageinfo.desc }}</p>
                </li>
                <li>
                    <p class="desc">地点：{{ imageinfo.addr }}</p>
                </li>
            </ul>
            <img :src="getImageUrl(imageinfo.image_min_path)" style="width:70%;" />
            <p class="imagestate"><img :src="getImageUrlByState(imageinfo.risktype_index)" style="width:15px;" /><span :style="getClassNameByState(imageinfo.suggestion_index)">{{imageinfo.suggestion_desc}}</span>&nbsp;|&nbsp;{{imageinfo.risktype_desc}}</p>
            <button @click="detailRequest(imageinfo.id)" style="font-size:0.8em">详细信息</button>
            <p class="imagestate">{{ imageinfo.uploadtime }}</p>
        </div>
    </div>
    <div class="page_selector" v-if="pageCount">
        <n-pagination
            v-model:page="curpage"
            v-model:page-size="pageSize"
            :page-count="pageCount"
            @update-page="pageChanged"
        />
    </div>
</div>

<dialog ref="detailInfo" @click="dialogClick" id="image_detail_dialog">
    <div class="detail_dialog_container">
        <div style="width:80%;background-color:#f3f2f1;display:flex;align-items: center;">
            <img :src="getImageUrl(imageDetailInfo.image_path)" />
        </div>
        <div class="image_desc" style="overflow-y:auto;">
            <p style="margin-bottom:20px;">基本信息</p> 
            <div class="image_info_text_dialog">
                <p style="font-weight: bold;">创建时间：</p>
                <p style="margin-bottom:10px;">{{ imageDetailInfo.uploadtime }}</p>
                <p style="font-weight: bold;">地址：</p>
                <p>{{ imageDetailInfo.addr }}</p>
            </div>
            <div class="tab_bar_c">
                <n-button @click="imgTypeClick">图片</n-button>
                <n-button @click="textTypeClick">文本</n-button>
            </div>
            <div class="image_detail_labels" v-if="isShowImageDetails">
                <n-data-table 
                    :columns="columns"
                    :data="imageDetailInfo.detail_labels"
                    :row-class-name="rowClassName"
                    children-key="objects" />
            </div>
            <div class="image_detail_labels" style="max-width:500px;" v-if="!isShowImageDetails">
                <n-data-table 
                    :columns="textTableColumns"
                    :data="textTable"
                    :row-class-name="rowClassNameForTextTable"
                    :max-height="'80%'" />
            </div>
        </div>
    </div>
</dialog>
<dialog @click="closeWaringDialog" ref="waringdialog" id="waring_dialog">
    <div class="waring_dialog_container">
        <div class="image">
            <ul style="padding-left:20px;">
                <li>
                    <span class="desc">{{ waringImageInfo.desc }}</span>
                </li>
                <li>
                    <span class="desc">地点：{{ waringImageInfo.addr }}</span>
                </li>
            </ul>
            <img :src="getImageUrl(waringImageInfo.image_path)" />
            <p class="imagestate"><img :src="getImageUrlByState(waringImageInfo.risktype_index)" style="width:30px;" /><span :style="getClassNameByState(waringImageInfo.suggestion_index)">{{ waringImageInfo.suggestion_desc }}</span> | {{ waringImageInfo.risktype_desc }}</p>
            <p class="imagestate">{{ waringImageInfo.uploadtime }}</p>
        </div>
        <div class="waring_container">
            <img src="../../assets/tishi.png" width="50" height="50">
            <p class="waring_font_color">提示</p>
            <p class="waring_font_color waring_font_bigsize">发现违规展示内容</p>
            <p class="waring_font_color">{{ waringImageInfo.uploadtime }}</p>
            <p class="waring_font_color">{{ waringImageInfo.addr }}</p>
        </div>
        <img class="float_close_button" src="../../assets/guanbi.png" width="50" height="50" @click="closeWaringDialog" />
    </div>
</dialog>
<audio ref="alertAudio">
    <source src="../../assets/alert.mp3" type="audio/mpeg">
</audio>
</template>

<script>

import { inject } from 'vue'
import axios from 'axios'
import passImage from '../../assets/pass.png'
import rejustImage from '../../assets/rejust.png'
import { NPagination,NDataTable, NButton } from 'naive-ui';

export default {
    props:['show_sites', 'is_show_outlaw'],
    components:{
        NPagination,
        NDataTable,
        NButton
    },
    data(){
        return{
            serverUrl:"",
            curpage: 1,
            pageCount: 0,
            pageSize: 15,
            websocketHandle : null,
            imagelist:[],
            imageDetailInfo: {},
            waringImageInfo: {},
            isshowwaringdialog: true,
            cancelDialogTimeoutHandle: null,
            isShowImageDetails: true,
            textTable:[],
            columns : [
                {
                    title: "应用",
                    key: "name",
                    width:'50%',
                },
                {
                    title: "标签",
                    key: "label",
                },
                {
                    title: "分数",
                    key: "rate",
                }
            ],
            textTableColumns:[
                {
                    title: "内容",
                    key: "text",
                    width:'70%'
                },
                {
                    title: "标签",
                    key: "label",
                },
            ]
        }
    },
    unmounted(){
        if(this.websocketHandle){
            this.websocketHandle.close()
        }
    },
    methods:{
        detailRequest(imageid){
            for(let imageInfo of this.imagelist){
                if(imageInfo.id == imageid){
                    this.imageDetailInfo = imageInfo
                }
            }
            for(let item of this.imageDetailInfo.detail_labels){
                if(item.rate < 2){
                    item.rate = this.fixNumber(item.rate)
                }
                if(item.objects){
                    for(let obj of item.objects){
                        if(obj.rate < 2){
                            obj.rate = this.fixNumber(obj.rate)
                        }
                    }
                }
            }
            this.textTable = this.imageDetailInfo.detail_labels[1].object
            this.$refs.detailInfo.showModal()
        },
        dialogClick(e){
            if(e.target.nodeName == 'DIALOG'){
                this.$refs.detailInfo.close()
                this.imageDetailInfo = {}
                this.isShowImageDetails = true
            }
        },
        connectWebsocket(url){
            this.websocketHandle = new WebSocket(url)
            this.websocketHandle.onmessage = res=>{
                let d = JSON.parse(res.data)
                if(this.curpage == 1){
                    for(let site of this.show_sites){
                        if(site == d.belone_to){
                            d.detail_labels = JSON.parse(d.detail_labels)
                            if(this.is_show_outlaw){
                                if(d.risktype_index != 0 || d.suggestion_index!=0){
                                    this.imagelist.unshift(d)
                                }
                            }else{
                                this.imagelist.unshift(d)
                            }
                            
                            if(this.imagelist.length > this.pageSize){
                                this.imagelist.pop()
                            }
                            break
                        }
                    }
                }
                if(d.risktype_index != 0 || d.suggestion_index!=0){
                    this.$refs.alertAudio.play()
                    if(this.isshowwaringdialog){
                        this.waringImageInfo = d
                        this.$refs.waringdialog.showModal()
                        if(this.cancelDialogTimeoutHandle){
                            clearTimeout(this.cancelDialogTimeoutHandle)
                        }
                        this.cancelDialogTimeoutHandle = setTimeout(() => {
                            this.$refs.waringdialog.close()
                            this.cancelDialogTimeoutHandle = null
                        }, 5000);
                    }
                }
            }
        },
        pageChanged(index){
            let postdata = ''
            for(const sid of this.show_sites){
                postdata += 'sid='+sid
                postdata += '&'
            }
            postdata += 'index='+(index-1)*this.pageSize
            postdata += '&isoutlaw=' + this.is_show_outlaw
            this.curpage = index
            axios.post(this.serverUrl + "imagelist", postdata).then(res=>{
                this.imagelist = res.data.data
                this.pageCount = Math.floor(res.data.count / this.pageSize)+1
                for(let imageInfo of this.imagelist){
                    imageInfo.detail_labels = JSON.parse(imageInfo.detail_labels)
                }
            })
        },
        requestImagelist(){
            let postdata = ''
            for(const sid of this.show_sites){
                postdata += 'sid='+sid
                postdata += '&'
            }
            postdata += 'index=0&' + 'isoutlaw=' + this.is_show_outlaw
            axios.post(this.serverUrl + "imagelist", postdata).then(res=>{
                this.imagelist = res.data.data
                this.pageCount = Math.floor(res.data.count / 15)+1
                for(let imageInfo of this.imagelist){
                    imageInfo.detail_labels = JSON.parse(imageInfo.detail_labels)
                }
            })
        },
        closeWaringDialog(){
            this.$refs.waringdialog.close()
            this.isshowwaringdialog = false
        },
        imgTypeClick(){
            this.isShowImageDetails = true
        },
        textTypeClick(){
            this.isShowImageDetails = false
        }
    },
    watch:{
        'show_sites':{
            handler(newValue, oldValue){
                this.requestImagelist()
            }
        },
        'is_show_outlaw':{
            handler(newValue, oldValue){
                this.requestImagelist()
            }
        }
    },
    computed:{
        getImageUrl(){
            let self = this
            return function(url){
                if(url){
                    const rurl = self.serverUrl + 'getimage?path='+url
                    return rurl
                }else{
                    return ''
                }
            }
        },
        getClassNameByState(){
            return function(state){
                if(state == 0){
                    return 'color:#0f0;font-size:0.8rem;'
                }else{
                    return 'color:#f00;font-size:0.8rem;'
                }
            }
        },
        getImageUrlByState(){
            return function(state){
                if(state == 0){
                    return passImage
                }else{
                    return rejustImage
                }
            }
        },
        getButtonStyleByIndex(){
            let self = this
            return function(index){
                let styleStr = 'width: 30px;height:30px;'
                if(index == self.curpage){
                    styleStr += 'background-color:#fff;'
                }
                if(index > self.curpage +3 && index !=self.pageCount-1){
                    styleStr += 'display:none;'
                }
                if(index < self.curpage -2 && index != 1){   
                    styleStr += 'display:none;'
                }
                return styleStr
            }
        },
        fixNumber(){
            return function(num){
                if(num){
                    return Math.round(num * 10000) / 100;
                }else{
                    return ''
                }
            }
        },
        getStyleByProperty(){
            return function(index, isInlaw){
                let styleStr = ''
                if(index == 0){
                    styleStr += 'width:35%;'
                }else if(index == 1){
                    styleStr += 'width:50%;'
                }else{
                    styleStr += 'width:15%;'
                }
                if(!isInlaw){
                    styleStr += "color:#f00;"
                }
                return styleStr
            }
        },
        rowClassName(){
            return row=>{
                if(row.isinlaw){
                    return ''
                }else{
                    return 'outlawcolor'
                }
            }
        },
        rowClassNameForTextTable(){
            return row=>{
                if(row.label == '正常'){
                    return ''
                }else{
                    return 'outlawcolor'
                }
            }
        },
    },
    mounted(){
        this.serverUrl = inject('serverUrl')
        let websocketUrl = inject('websocketurl')
        this.connectWebsocket(websocketUrl)
    }
}
</script>

<style scoped>
.imgage_containter{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    grid-auto-rows:auto;
    justify-items: center;
    align-items: center;
    gap: 2px;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 50px)
}

.waring_container{
    width: 180%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.show_area{
    width:100%;
    height: 93%;
    display: flex;
    flex-direction: column;
    background-image: url(../../assets/矩形510.png);
    background-size: 100% 100%;
}

.page_selector{
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    gap: 5px;
}

.detail_dialog_container{
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    gap: 20px;
}

.waring_dialog_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    border:none;
}

:deep(.outlawcolor td) {
    color: rgba(255, 0, 0, 0.75) !important;
}

.waring_font_color{
    color: #ff6602;
    font-weight: bolder;
}

.waring_font_bigsize{
    font-size: 2em;
}

.float_close_button{
    position: absolute;
    left: 45%;
    top: 3%;
    cursor: pointer;
}

.image{
    background-color: #f3f1f2;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 5px;
    gap: 2px;
    align-items: center;
    justify-content: space-between;
}

.desc{
    font-size: 0.7rem;
}

p,desc{
    font-weight: bold;
}

.imagestate{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
}

.dialog_table_row{
    border-bottom: 1px solid #000;
}

.image_info_text_dialog{
    background-color: #f3f1f2;
    padding:10px;
}

#image_detail_dialog:modal{
    position: fixed;
    inset-block-start: 0px;
    inset-block-end: 0px;
    width: 86%;
    height: 95%;
    visibility: visible;
    overflow: hidden;
    top:2.5%;
    left: 7%;
}

#waring_dialog:modal{
    position: fixed;
    inset-block-start: 0px;
    inset-block-end: 0px;
    width: 50%;
    height: 40%;
    top:25%;
    left: 25%;
    visibility: visible;
    overflow: hidden;
}

dialog::backdrop{
    background-color: rgba(1, 1, 1, 0.5)
}

dialog {
    border: none;
    box-shadow: none;
}

img{
    width: 100%;
    object-fit: contain;
}

.tab_bar_c{
    display: flex;
    align-items: center;
    justify-content: start;
}
</style>