import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'

const app = createApp(App)

const t = new Date()

const websocketUrl = 'ws://' + window.location.host + '/notify/web'+t.getTime() + '/'
const serverUrl = "/aishow/"

//const serverUrl = "http://39.106.134.238/aishow/"

app.provide('serverUrl', serverUrl)
app.provide('websocketurl', websocketUrl)
app.use(router)

app.mount('#app')
