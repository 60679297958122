<template>
	<div class="aidefender_c">
		<DeviceList @sitechanged="siteChanged" />
		<div class="image_c">
			<ImageTabBar @lawChanged="lawChanged" />
			<ImageInfo :show_sites="showSites" :is_show_outlaw="isShowOutlaw" />
		</div>
	</div>
</template>

<script>

import DeviceList from './DeviceList.vue'
import ImageInfo from './ImageInfo.vue'
import ImageTabBar from './ImageTabBar.vue'
export default ({
	components:{
		DeviceList,
		ImageInfo,
		ImageTabBar
	},
	data() {
		return {
			serverUrl: '',
			isShowOutlaw: false,
			showSites: []
		}
	},
	methods:{
		siteChanged(idarr){
			this.showSites = idarr
		},
		lawChanged(isOutlaw){
			this.isShowOutlaw = isOutlaw == 1
		}
	}
})


</script>

<style scoped>
.aidefender_c{
	display: flex;
	gap: 1%;
	width: 100%;
	height: 100%;
}

.image_c{
	width:100%;
	height:100%;
}
</style>
