import { createRouter, createWebHashHistory } from 'vue-router'
import MainWindow from './components/MainWindow.vue'
import LoginView from './components/LoginView.vue'
const router = createRouter({
    history: createWebHashHistory(),
    routes:[
        {
            path: '/',
            component: LoginView,
            name: 'start'
        },
        {
            path: '/mainwindow',
            component: MainWindow,
            name: 'mainwindow',
        },
    ]
})

export default router