<template>
	<div class="image_tabbar_c">
        <div class="normal_btn" :class="{selected_btn:selectedBtnIndex==0}" @click="inlawBtnClick" >
            <p>实时分析</p>
        </div>
        <div class="normal_btn outlaw_btn" :class="{selected_btn:selectedBtnIndex==1}" @click="outlawBtnClick">
            <p>违规结果</p>
        </div>
	</div>
</template>

<script>
export default ({
	data() {
		return {
			selectedBtnIndex: 0
		}
	},
	methods:{
        inlawBtnClick(){
            this.selectedBtnIndex = 0
            this.$emit('lawChanged', 0)
        },
        outlawBtnClick(){
            this.selectedBtnIndex = 1
            this.$emit('lawChanged', 1)
        }
    }
})


</script>

<style scoped>
.image_tabbar_c{
    width:100%;
    height:7%;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.normal_btn{
    height: 100%;
    padding: 20px 40px;
    font-weight: bold;
    border-top-right-radius: 20px;
    cursor: pointer;
}

.outlaw_btn{
    border-top-left-radius: 20px;
}

.selected_btn{
    background-color: #29207a;
    color:#fff
}
</style>
