<template>
	<div class="videobg" :class="{bgimg:!isPlaying}">
		<video ref="videoPlayer" muted>
		</video>
        <p id="tips" v-if="!isPlaying">请将要显示的摄像机画面拖拽到本区域</p>
        <button v-if="livenames.length>0" id="dropdown" ref="dropdown" @click="showDropdown"><p>{{ livenames[curlivenameindex].addr }}</p><img src="../../assets/更多.png" ></button>
	</div>
    <dialog @click="closeDropdownDialog" ref="dropdown_dialog" id="dropdown_dialog" :style="dialog_pos">
        <div >
            <ul >
                <li v-for="item in livenames" :key="item" style="margin-bottom:2px;">
                    <p :title=item.addr style="background-color:#00000066;color:#fff;padding:3px;" @click="dropdownItemClicked(item)">{{ item.addr }}</p>
                </li>
            </ul>
        </div>
    </dialog>
</template>

<script>
import mpegts from 'mpegts.js'
export default ({
	data() {
		return {
			serverUrl: '',
            flvPlayer : null,
            isPlaying : false,
            livenames: [],
            curlivenameindex: 0,
            dialog_pos:{left:'20px',top:'20%'}
		}
	},
    methods:{
        handleSelect (key) {
            console.log(key)
        },
        setSiteName(names){
            this.livenames = names
            if(this.flvPlayer){
                this.freePlayer()
            }
            if(this.livenames.length > 0){
                const url = `http://${window.location.host}/live?port=1935&app=live&stream=${this.livenames[0].livename}`
                this.createPlayer(url)
            }
            //const url = `http://39.106.134.238/live?port=1935&app=live&stream=site1`
            
        },
        playStarted(){
            this.isPlaying = true
        },
        freePlayer(){
            this.flvPlayer.pause()
            this.flvPlayer.unload()
            this.flvPlayer.detachMediaElement()
            this.flvPlayer.destroy()
            this.flvPlayer = null
        },
        stopLiveStream(){
            this.freePlayer()
            this.flvPlayer = false
        },
        showDropdown(){
            this.$refs.dropdown_dialog.showModal()
            const rect = this.$refs.dropdown.getBoundingClientRect();
            this.dialog_pos.top = rect.bottom + 5 + 'px'
            this.dialog_pos.left = rect.left + 'px'
        },
        closeDropdownDialog(e){
            if(e.target.nodeName == 'DIALOG'){
                this.$refs.dropdown_dialog.close()
            }
        },
        createPlayer(url){
            const self = this
            this.flvPlayer = mpegts.createPlayer({
                type:'flv',
                url:url,
                isLive: true,
                hasVideo:true,
                hasAudio:false,
            },{liveSync:true,liveSyncPlaybackRate:2})
            this.flvPlayer.on(mpegts.Events.ERROR, (err, errdet)=>{
                if(err == mpegts.ErrorTypes.MEDIA_ERROR){
                    if(errdet == mpegts.ErrorDetails.MEDIA_FORMAT_UNSUPPORTED){
                        console.log('格式不支持')
                    }
                }
                if(err == mpegts.ErrorTypes.NETWORK_ERROR){
                    console.log('网络错误', errdet)
                }
                if(err == mpegts.ErrorTypes.OTHER_ERROR){
                    console.log('其他错误', errdet)
                }
            })
            this.flvPlayer.on(mpegts.Events.RECOVERED_EARLY_EOF, ()=>{
                console.log('play end')
            })
            this.flvPlayer.attachMediaElement(this.$refs.videoPlayer)
            this.flvPlayer.load()
            this.flvPlayer.play().then(()=>{
                self.playStarted()
            })
        },
        dropdownItemClicked(item){
            console.log(item)
            this.$refs.dropdown_dialog.close()
        },
        playCurTime(){
            if(this.flvPlayer){
                const buffered = this.flvPlayer.buffered.end(0) - 0.1
                if (buffered - this.flvPlayer.currentTime > 1) {
                    this.flvPlayer.currentTime = buffered
                }
            }
        },
    },
	beforeUnmount() {
        if(this.flvPlayer){
            this.freePlayer()
        }
	},
    mounted(){
        const self = this
        document.addEventListener("visibilitychange", function () {
            self.playCurTime()
        })
    }
})


</script>

<style scoped>
video{
    width: 100%;
    height:100%;
}

.videobg{
    width: 100%;
    height: 90%;
    background-color: #000;
    position: relative;
}

.bgimg{
    background-image: url(../../assets/摄像头.png);
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: center;
}

#tips{
    color: #fff;
    position: absolute;
    width: 100%;
    line-height: 100%;
    text-align: center;
    top:50%;
}

#dropdown{
    position: absolute;
    top: 5%;
    left: 3%;
    padding:4px;
    max-width: 50%;
    background-color: #00000066;
    border: 1px solid #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

p{
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}

#dropdown_dialog:modal{
    position: fixed;
    inset-block-start: 0px;
    inset-block-end: 0px;
    width: 20%;
    visibility: visible;
    overflow: hidden;
    background-color: transparent;
}

dialog::backdrop{
    background-color: rgba(1, 1, 1, 0)
}

dialog {
    border: none;
    box-shadow: none;
}
</style>
