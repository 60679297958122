<template>
<div class="dev_containter">
	<h1 style="margin-bottom:20px;margin-top:10px;">设备列表</h1>
	<n-tree 
		:data="data"
		expand-on-click
		:node-props="nodeClick"
		key-field="id"
		label-field="name"
		children-field="sites"
		selectable
		:draggable="dragable"
    />
	<n-dropdown
		placement="bottom-start"
		trigger="manual"
		:x="menuX"
		:y="menuY"
		:options="options"
		:show="showContextMenu"
		:on-clickoutside="onClickoutside"
		@select="handleSelect"
	/>
</div>
</template>

<script>

import { inject,nextTick } from 'vue';
import axios from 'axios';
import { NTree,NDropdown } from 'naive-ui'

export default {
	components:{
		NTree,
		NDropdown
	},
	data(){
		return{
			serverUrl: '',
			data:[],
			dragable: true,
			menuX: 0,
			menuY: 0,
			options: [],
			showContextMenu: false,
		}
	},
	mounted(){
		this.serverUrl = inject('serverUrl')
		axios.get(this.serverUrl + 'getlocationinfo', {
			withCredentials:true,
		}).then(res=>{
			this.data = res.data
			let sitelist = []
			for(let loc of this.data){
				for(let site of loc.sites){
					sitelist.push(site.id)
				}
			}
			this.$emit('sitechanged', sitelist)
		}).catch(res=>{
			if(res.response.status == 986){
				this.$router.push({
					name:'start'
				})
			}
		})
	},
	methods:{
		onClickoutside(){
			this.showContextMenu = false
		},
		handleSelect(value){
			this.showContextMenu = false
			if(value.type == 'change_stream'){
				axios.get(`/aishow/changestreams?value=${value.value}&name=${value.name}`)
			}
		},
		locationClick(lid){
			for(let linfo of this.locationData){
				let sitelist = []
				if(linfo.id == lid){
					for(let siteInfo of linfo.sites){
						sitelist.push(siteInfo.id)
					}
					this.$emit('sitechanged', sitelist)
					let store = window.localStorage
					store['selectedsite'] = sitelist
					break;
				}
			}
		},
		showMyContextMenu(option, x, y){
			this.showContextMenu = false;
			this.options = option
			const self = this
			nextTick().then(() => {
				this.showContextMenu = true;
				self.menuX = x;
				self.menuY = y;
			});
		},
		nodeClick({option}){
			const self = this
			return {
				onClick(){
					if(option.type == 'site'){
						self.$emit('sitechanged', [option.id])
						let store = window.localStorage
						store['selectedsite'] = [option.id]
					}
				},
				ondragstart(e){
					let objs = []
					if(option.type == "location"){
						objs = option.sites
					}else{
						objs.push(option)
					}
					e.dataTransfer.setData('text', JSON.stringify(objs))
				},
				oncontextmenu(e){
					if(option.type == 'site'){
						e.preventDefault();
						self.showMyContextMenu([{
							label:'断流',
							key:{
								type: 'change_stream',
								value: 0,
								name: option.name,
							}
						},{
							label:'恢复',
							key:{
								type: 'change_stream',
								value: 1,
								name: option.name,
							}
						}], e.clientX, e.clientY)
					}
				}
			}
		}
	},
}
</script>

<style scoped>
.dev_containter{
	width: 20%;
	padding-left:20px;
	background-color: #fff;
	height: 100%;
	padding-top: 10px;
}

</style>