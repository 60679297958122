<template>
	<div id="background_continaer">
		<div id="title">
            <img src="../assets/警徽3.png">
            <span style="font-size:2.5rem;color:#fff;font-weight:bold">公共场所电子显示屏 内容安全智能分析管理平台</span>
        </div>
        <div class="input_frame" ref="username_frame">
            <img src="../assets/账号.png" width="25px" height="30px">
            <input type="text" placeholder="输入帐号" ref="input_username">
        </div>
        <div class="input_frame" ref="password_frame">
            <img src="../assets/密码.png" width="25px" height="30px">
            <input type="password" placeholder="输入密码" ref="input_password" @keydown="checkkeydown">
        </div>
        <div id="button_group">
            <div class="button" @click="registerClicked()">
                <img src="../assets/注册.png" width="25px" height="25px">
                <p style="font-family: '楷体';">注册</p>
            </div>
            <div class="button" @click="loginClick()">
                <img src="../assets/登录.png" width="25px" height="25px">
                <p style="font-family: '楷体';">登录</p>
            </div>
        </div>
	</div>
    <dialog @click="closeWaringDialog" ref="waringdialog" id="waring_dialog">
        <div id="dialog_bg">
            <button style="position:relative;left:37%;top:68%;width:25%;height:20%;background-color:transparent;border:none;outline:none;" @click="closeDialog"></button>
        </div>
    </dialog>
    <p :style="errorStyleObj">{{ errorMsg }}</p>
</template>

<script>
import axios from 'axios'

export default ({
	data() {
		return {
			errorMsgLeft: '0px',
            errorMsgTop: '0px',
            errorMsg: ''
		}
	},
    mounted(){
        const self = this
        window.addEventListener('resize', res=>{
            if(self.errorMsg == '*用户名不存在'){
                const rect = self.$refs.username_frame.getBoundingClientRect()
                self.errorMsgLeft = rect.right + 'px'
                self.errorMsgTop = rect.top + rect.height/4 + 'px'
            }else if(self.errorMsg == '*密码错误'){
                const rect = self.$refs.password_frame.getBoundingClientRect()
                self.errorMsgLeft = rect.right + 'px'
                self.errorMsgTop = rect.top + rect.height/4 + 'px'
            }
        })
    },
    computed:{
        errorStyleObj(){
            return{
                position: 'fixed',
                color: '#f00',
                fontSize: '0.8rem',
                left: this.errorMsgLeft,
                top: this.errorMsgTop
            }
        }
    },
	methods:{
        registerClicked(){
            this.$refs.waringdialog.showModal()
        },
        loginClick(){
            const formdata = new FormData()
            formdata.append('username', this.$refs.input_username.value)
            formdata.append('password', this.$refs.input_password.value)
            const self = this
            axios.post('aishow/userlogin', formdata).then(res=>{
                if(res.data == -1){
                    const rect = self.$refs.username_frame.getBoundingClientRect()
                    self.errorMsgLeft = rect.right + 'px'
                    self.errorMsgTop = rect.top + rect.height/4 + 'px'
                    self.errorMsg = '*用户名不存在'
                }else if(res.data == -2){
                    const rect = self.$refs.password_frame.getBoundingClientRect()
                    self.errorMsgLeft = rect.right + 'px'
                    self.errorMsgTop = rect.top + rect.height/4 + 'px'
                    self.errorMsg = '*密码错误'
                }else{
                    self.errorMsg = ''
                    self.$router.replace( {
                        name: 'mainwindow',
                    })
                }
            })
        },
        closeWaringDialog(e){
            if(e.target.nodeName == 'DIALOG'){
                this.closeDialog()
            }
        },
        closeDialog(){
            this.$refs.waringdialog.close()
        },
        checkkeydown(e){
            if(e.keyCode == 13){
                this.loginClick()
            }
        }
    }
})


</script>

<style scoped>
#background_continaer{
    background-image: url(../assets/AI背景5.png);
    height: 100%;
    background-size: 100% 100%;
    background-repeat:no-repeat;
    display: flex;
    flex-direction: column;
    gap: 3%;
    align-items: center;
    padding-top: 5%;
}

#title{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 3%;
}

input{
    width: 80%;
    height: 100%;
    background: none;
    border: none;
    color: #fff;
}

::-ms-reveal {
    display: none;
}

input::-webkit-input-placeholder{
    font-family: '楷体';
}

input:focus{
    border: none;
    outline: none;
}

#button_group{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    gap: 10%;
}

.button{
    width: 45%;
    height: 50px;
    background-color: #0f2db2;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.input_frame{
    width: 20%;
    height:50px;
    border-radius: 5px;
    background-color: #e2e2e220;
    padding-left:20px;
    display: flex;
    gap: 20px;
    align-items: center;
}

#waring_dialog:modal{
    position: fixed;
    inset-block-start: 0px;
    inset-block-end: 0px;
    width: 20%;
    height: 15%;
    top:40%;
    left: 40%;
    visibility: visible;
    overflow: hidden;
    animation: slideDown 0.2s ease;
    background-color: transparent;
    outline: none;
    border: none;
}

dialog::backdrop{
    background-color: rgba(1, 1, 1, 0.5)
}

@keyframes slideDown {
    from {
      transform: translateY(-100px);
    }
  
    to {
      transform: translateY(0);
    }
}

#dialog_bg{
    background-image: url(../assets/dialog_bg.png);
    height: 100%;
    background-size: 100% 100%;
    background-repeat:no-repeat;
}
</style>
