<template>
    <div class="livectrl_c">
        <DeviceList />
        <div class="livearea_c">
            <div class="livetop_c">
                <p>实时监控</p>
                <div class="livegrid">
                    <div class="grid_item" v-for="item in liveCount" :key="item" @drop="dropItem($event,item)" @dragover="dragOver">
                        <div class="video_desc">
                            <img src="../../../assets/监控.png" style="height:80%;" >
                            <p>1111</p>
                            <n-switch :rail-style="railStyle" v-model:value="item.switchState" :disabled="!item.switchState" @update:value="trunOffLiveStream(item)">
                                <template #checked>
                                </template>
                                <template #unchecked>
                                </template>
                            </n-switch>
                        </div>
                        <LivePage :ref="getRefName(item)" />
                    </div>
                </div>
            </div>
            <div class="livemsg">
                <LiveMsg />
            </div>
        </div>
    </div>
</template>

<script>

import DeviceList from '../DeviceList.vue'
import LivePage from '../LivePage.vue'
import LiveMsg from './LiveMsg.vue'
import { NSwitch } from 'naive-ui'
export default ({
    components:{
        DeviceList,
        LivePage,
        NSwitch,
        LiveMsg
    },
	data() {
		return {
			liveCount:[
                {
                    index:0,
                    switchState:false
                },
                {
                    index:1,
                    switchState:false
                },
                {
                    index:2,
                    switchState:false
                },
                {
                    index:3,
                    switchState:false
                }
            ]
		}
	},
	methods:{
        dropItem(e, item){
            e.preventDefault();
            const option = JSON.parse(e.dataTransfer.getData('text'))
            this.$refs['livePage'+item.index][0].setSiteName(option)
            item.switchState = true
        },
        dragOver(e){
            e.preventDefault();
        },
        trunOffLiveStream(item){
            this.$refs['livePage'+item.index][0].stopLiveStream()
        }
    },
    computed:{
        getRefName(){
            return function(item){
                return 'livePage' + item.index
            }
        }
    },
    setup() {
        return {
            railStyle: ({
                focused,
                checked
            }) => {
                const style = {};
                if (checked) {
                    style.background = "#0f0";
                    if (focused) {
                        style.boxShadow = "0 0 0 0 #d0305040";
                    }
                }else {
                    style.background = "#f00";
                    if (focused) {
                        style.boxShadow = "0 0 0 0 #2080f040";
                    }
                }
                return style;
            }
        };
    }
})


</script>

<style scoped>
.livectrl_c{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1%;
}

.livearea_c{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1%;
}

.livetop_c{
    background-color: #fff;
    width: 100%;
    height:70%;
    padding:10px 30px 10px 30px;
}

.livegrid{
    width: 100%;
    height: calc(100% - 30px);
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-template-rows: repeat(2, 50%);
    gap: 1%;
}

.livemsg{
    width: 100%;
    height: 30%;
}

.video_desc{
    height:10%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2%;
}
</style>
