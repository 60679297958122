<template>
	<div id="tabbar_c">
        <div id="tabar_list">
            <div :class="['tabitem', item.selected?'selectedstate':'nstate']" v-for="(item,index) in tablist" :key="item" @click="tabItemClicked(index)">
                <img :src="item.icon">
                <p>{{ item.name }}</p>
            </div>
        </div>
        <div id="copyrightico">
            <img src="../assets/光影立方LOGO英文-白字.png" style="margin-bottom:20px;">
            <p style="font-size:0.8rem">北京光影立方科技有限公司</p>
            <p style="font-size:0.8rem">版权所有 @2023</p>
        </div>
	</div>
</template>

<script>

import datas from '../assets/报表统计分析.png'
import live from '../assets/实时监控(1).png'
import ai from '../assets/ai.png'
import masm from '../assets/ico_设备管理.png'
import sysm from '../assets/系统设置(1).png'

export default ({
	data() {
		return {
            tablist: [
                {
                    name:'数据概览', 
                    icon: datas,
                    selected:false
                },
                {
                    name:'实时监控', 
                    icon:live,
                    selected:false
                },
                {
                    name:'智能防护', 
                    icon:ai,
                    selected:false
                },
                {
                    name:'设备管理', 
                    icon:masm,
                    selected:false
                },
                {
                    name:'系统管理', 
                    icon:sysm,
                    selected:false
                }
            ]
		}
	},
	mounted() {

	},
    methods:{
        tabItemClicked(index){
            this.$emit('tabchanged', index)
            for(let item of this.tablist){
                item.selected = false
            }
            this.tablist[index].selected = true
        }
    },
})


</script>

<style scoped>
#tabbar_c{
    width: 10%;
    background-color: #cecece;
    display: flex;
    flex-direction: column;
    
}

#tabar_list{
    height: 80%;
}

#copyrightico{
    height: 20%;
    background-color: #2b30a3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5%;
}

.nstate{
    background-color: #2b30a3;
}

.selectedstate{
    background-color: #161f5c;
}

.tabitem{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20%;
    border-bottom: 1px solid #cecece;
    justify-content: center;
    gap: 10%;
    cursor: pointer;
}

.tabitem:hover{
    background-color: #161f5c;
}

p{
    color: #fff;
    font-family: '楷体';
    font-weight: bold;
}
</style>
